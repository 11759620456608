import axios from 'axios';
import * as authService from "./AuthService";
//import { store } from '../store/store';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use((config) => {
    /*const state = store.getState();
    const token = state.auth.auth.idToken;
    config.params = config.params || {};
    config.params['auth'] = token;
    return config;*/
    const currentUser = authService.getCurrentUser();
    if (currentUser) {
        config.headers.common.Authorization = `Bearer ${currentUser.access_token}`;
    }
    return config;
});

axiosInstance.interceptors.response.use(undefined, error => {
    const {status} = error.response;
    if (status === 401) {
        //dispatch(logout(history));
        window.location = '/login';
    }
    return Promise.reject(error);
});

export default axiosInstance;
