import React, {useEffect, useState} from 'react';
import  {Link} from 'react-router-dom';
import Logout from './Logout';
import * as authService from "../../services/AuthService";
//import userService from "../../services/user.service";

const Header3 = () => {
	const [isAdmin, setIsAdmin] = useState(false);
	useEffect(() => {
		const currentUser = authService.getCurrentUser();
		if (currentUser) {
			if (currentUser.role === 1) {
				setIsAdmin(true);
			}
		}
	}, []);

	// sidebar open/close
	useEffect(() => {
		const btn = document.querySelector('.navicon');
		const aaa = document.querySelector('.myNavbar ');

		function toggleFunc() {
			return aaa.classList.toggle("show");
		}

		btn.addEventListener('click', toggleFunc);


		// Sidenav li open close
		const navUl = [].slice.call(document.querySelectorAll('.navbar-nav > li'));
		for (let y = 0; y < navUl.length; y++) {
			navUl[y].addEventListener('click', function () { checkLi(this, navUl) });
		}
	}, []);

	function checkLi(current, navUl) {
		navUl.forEach(el => el.classList.remove('open'));
		current.classList.add('open');
	}

	return(
		<header className="site-header header-transparent mo-left">

			<div className="sticky-header main-bar-wraper navbar-expand-lg">
				<div className="main-bar clearfix ">
					<div className="container clearfix">
						<div className="logo-header mostion">
							<Link to={"./"} className="logo-1"><img src={require("../../images/funfinder-gradient-white.png")} alt="" /></Link>
						</div>

						<button className="navbar-toggler collapsed navicon  justify-content-end" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
							<span></span>
							<span></span>
							<span></span>
						</button>

						<div className="extra-nav">
							<div className="extra-cell">
								<div className="site-button radius-xl">
									<Logout />
								</div>
							</div>
						</div>

						<div className="header-nav navbar-collapse collapse myNavbar justify-content-end" id="navbarNavDropdown">
							<ul className="nav navbar-nav">
								<li><Link to={"/"}>Home</Link></li>
								<li><Link to={"/listing-grid-map-right-sidebar"}>Search</Link></li>
								{ isAdmin && <li><Link to={"/admin"}>Admin</Link></li> }
							</ul>
						</div>
					</div>
				</div>
			</div>
		</header>
	)
}

export default Header3;
