import React,{  Suspense } from 'react';

import Index from './markup/Markup';
import { connect } from 'react-redux';
import {  Route, Switch, withRouter } from 'react-router-dom';
import { isAuthenticated } from './store/selectors/AuthSelectors';
import './css/plugins.css';
import './css/style.css';
import './css/templete.css';
import './css/skin/skin-1.css';
//import './plugins/slick/slick.min.css';
//import './plugins/slick/slick-theme.min.css';


import Login from './markup/Pages/Login';
import SignUp from './markup/Pages/Register';
import Admin from './markup/Pages/Admin';
import Gridmaprightsidebar from "./markup/Pages/Gridmaprightsidebar";
import Homepage2 from "./markup/Pages/Homepage2";
import Listingdetail2 from "./markup/Pages/Listingdetail2";

//const SignUp = lazy(() => import('./markup/Pages/Register2'));
//const ForgotPassword = lazy(() => import('./markup/pages/ForgotPassword'));
/*  const Login = lazy(() => {
    return new Promise(resolve => {
    setTimeout(() => resolve(import('./markup/Pages/Loginpage2')), 500);
  });
});  */


function App (props) {
    /*const dispatch = useDispatch();
    useEffect(() => {
        checkAutoLogin(dispatch, props.history);
    }, [dispatch, props.history]);*/
    
    let routes = (  
        <Switch>
            <Route path='/' exact component={Homepage2} index />
            <Route path='/login' component={Login} />
            <Route path='/register' component={SignUp} />
            <Route path='/home' component={Homepage2} />
            <Route path='/listing-grid-map-right-sidebar' component={Gridmaprightsidebar} />
            <Route path='/listing-details-2' component={Listingdetail2} />
            <Route path='/admin' component={Admin} />
        </Switch>
    );
    if (props.isAuthenticated) {
		return (
			<>
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>  
                   }
                >
                    <Index />
                </Suspense>
            </>
        );
	
	} else {
		return (
			<div className="vh-100">
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                  }
                >
                    {routes}
                </Suspense>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};

export default withRouter(connect(mapStateToProps)(App)); 

//export default App;
