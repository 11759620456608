import React, {useRef} from 'react';
import Button from 'react-bootstrap/Button';

import searchService from '../../services/search.service';
import Header from './../Layout/Header3';
import Footer from './../Layout/Footer';

const Admin = () =>{
	const form = useRef();
	return(
		<div className="page-wraper">
			<Header />
			<div className="page-content bg-white">
				<div className="dlab-bnr-inr overlay-black-middle"></div>
				<div className="section-full content-inner">
					<div className="container">
						<div className="row">
							<div className="col-lg-6 mb-4 mb-md-0">
							<p>
								<Button onClick={searchService.extractBusiness} variant="primary">
									Extract businesses
								</Button>
							</p>	
							<p>
								<Button onClick={searchService.extractCategory} variant="primary">
									Extract categories
								</Button>
							</p>
							<p>
								<Button onClick={searchService.extractEvent} variant="primary">
									Extract Events
								</Button>
							</p>
							<p>
								<Button onClick={searchService.extractCategory} variant="primary">
								Index Businesses
								</Button>
							</p>
								<div className="clearfix contact-form m-b30">
									<div className="section-head text-black">
										<h2 className="box-title">Admin form</h2>
										<div className="dlab-separator bg-primary"></div>
										<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
									</div>
									<div className="dzFormMsg"></div>
									<form className="dzForm" ref={form}>
										<input type="hidden" value="Contact" name="name" />
										<div className="row">
											<div className="col-md-6">
												<div className="form-group">
													<div className="input-group">
														<input name="dzName" type="text" className="form-control" placeholder="Your Name" />
													</div>
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-group">
													<div className="input-group"> 
														<input name="dzEmail" type="email" className="form-control"   placeholder="Your Email Id"  />
													</div>
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-group">
													<div className="input-group">
														<input name="dzOther[Phone]" type="text"  className="form-control" placeholder="Phone" />
													</div>
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-group">
													<div className="input-group">
														<input name="dzOther[Subject]" type="text"  className="form-control" placeholder="Subject" />
													</div>
												</div>
											</div>
											 <div className="col-md-12">
												<div className="form-group">
													<div className="input-group">
														 <textarea name="dzMessage" rows="4" className="form-control"  placeholder="Your Message..."></textarea> 
													</div>
												</div>
											</div>
											{/* <div className="col-md-12">
												<div className="recaptcha-bx">
													<div className="input-group">
														<div className="g-recaptcha" data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN" data-callback="verifyRecaptchaCallback" data-expired-callback="expiredRecaptchaCallback"></div>
														<input className="form-control d-none" style={{display:"none"}} data-recaptcha="true" required data-error="Please complete the Captcha" />
													</div>
												</div>
											</div> */}
											<div className="col-md-12">
												<button name="submit" type="submit" value="Submit" className="site-button button-lg radius-xl">SUBMIT NOW</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						
							<div className="col-lg-6 d-flex">
								<div  className="m-b30 align-self-stretch" style={{width:"100%", minHeight:"100%" }}>
								</div>
							</div>
							
						</div>
						
					</div>
				</div>
			</div>

			<Footer  />
		</div>
	)
	
}

export default Admin;