import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import { loadingToggleAction,loginAction,
} from '../../store/actions/AuthActions';
import bnr from './../../images/background/bg2.jpg';
import * as authService from "../../services/AuthService";

function Login (props) {
	const currentUser = authService.getCurrentUser();
	let userEmail = '';
	if (currentUser) {
		userEmail = currentUser.email;
	}
	const [email, setEmail] = useState(userEmail);
    let errorsObj = { email: '', password: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [password, setPassword] = useState('');

    const dispatch = useDispatch();

    function onLogin(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (email === '') {
            errorObj.email = 'Email is Required';
            error = true;
        }
        if (password === '') {
            errorObj.password = 'Password is Required';
            error = true;
        }
        setErrors(errorObj);
        if (error) {
			return ;
		}
		dispatch(loadingToggleAction(true));	
        dispatch(loginAction(email, password, props.history));
    }
	
	return(
		<div className="page-wraper">
			
			<div className="page-content dlab-login" style={{backgroundImage: "url("+ bnr +")", backgroundPosition: "top right", backgroundBlendMode:"screen"}}>
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-4 login-form-box">
							<div className="login-form">
								<div className="logo">
									<Link to={"./"}><img src={require("./../../images/funfinder-gradient-blk.png")} alt=""/></Link>
								</div>
								<div className=" nav">
									<div id="login" className="tab-pane active">
										<form className="dlab-form" onSubmit={onLogin}>
											<h3 className="form-title m-t0">Welcome back, please login to your account</h3>
											{props.errorMessage && (
												<div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2'>
													{props.errorMessage}
												</div>
											)}
											{props.successMessage && (
												<div className='bg-green-300 text-green-900 border border-green-900 p-1 my-2'>
													{props.successMessage}
												</div>
											)}
											<div className="form-group">
												<input type="email" className="form-control"
													name="email"
													placeholder="Email Address"
													value={email}
													autocomplete="email"
													required
													onChange={(e) => setEmail(e.target.value)}
												/>
												{errors.email && <div className="text-danger fs-12">{errors.email}</div>}
												
												{/* <input name="dzName" required="" className="form-control" placeholder="Username or Email Address" type="text"/> */}
											</div>
											<div className="form-group">
												{/* <input name="dzName" required="" className="form-control " placeholder="Type Password" type="password"/> */}
												<input type="password" className="form-control"
													   name="password"
													   value={password}
													   placeholder="Type Your Password"
													   autocomplete="current-password"
													   required
													onChange={(e) =>
														setPassword(e.target.value)
													}
												/>
												{errors.password && <div className="text-danger fs-12">{errors.password}</div>}
											</div>
											<div className="form-group">
												<button type="submit" className="site-button btn-block button-md">login</button>
											</div>
											<div className="form-group">
												<Link data-toggle="tab" to="#forgot-password" className="btn-link forgot-password  float-end"> Forgot Password</Link>
											</div>
										</form>
									</div>									
								</div>
							</div>
						</div>
						<div className="col-lg-8">
							<div className="content-info">
								<ul className="list-info">
									<li>
										<div className="dlab-box">
											<i className="fa fa-bullhorn"></i>
											<p>Get personalized advice from our artificial intelligence</p>
										</div>
									</li>
									<li>
										<div className="dlab-box">
											<i className="fa fa-car"></i>
											<p>Easily find restaurants, things to do & night life that are right for you</p>
										</div>
									</li>
									<li>
										<div className="dlab-box">
											<i className="fa fa-check"></i>
											<p>It's everything you need to curate an experience</p>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
	)
}
const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};
export default connect(mapStateToProps)(Login);