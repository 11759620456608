import {React, useEffect, useState} from 'react';
import GoogleMap from 'google-maps-react-markers'
import mapOptions from './map-options.json'
import Marker from './marker'
import './Map2.scss';
import searchService from "../../services/search.service";

const Map2 = (props) => {
    const [mapConfigs, setMapConfigs] = useState(null);
    const [mapReady, setMapReady] = useState(false);
    const [myMap, setMyMap] = useState(null);
    const [currCoordinates, setCurrCoordinates] = useState(props.locations)
    const [highlighted, setHighlighted] = useState(null)

    function getPath() {
        return currCoordinates.map(({latitude, longitude}) => ({lat: latitude, lng: longitude}));
    }

    const redrawAndResize = (map) => {
        if (mapReady) {
            const locations = getPath();
            if (locations.length) {
                const bounds = new window.google.maps.LatLngBounds();
                for (let i = 0; i < locations.length; i++) {
                    bounds.extend(locations[i]);
                }
                map.fitBounds(bounds);

                const flightPath = new window.google.maps.Polyline({
                    path: locations,
                    geodesic: true,
                    strokeColor: '#33BD4E',
                    strokeOpacity: 1,
                    strokeWeight: 3
                });
                flightPath.setMap(map);
            }
        }
    };

    /**
     * @description This function is called when the map is ready
     * @param {Object} map - reference to the map instance
     * @param {Object} maps - reference to the maps library
     */
    const onGoogleApiLoaded = ({ map, maps }) => {
        setMyMap(map);
        setMapReady(true);
        redrawAndResize(map);
    }

    const onMapChange = ({ bounds, zoom }) => {
        redrawAndResize(myMap);
    }

    const onMarkerClick = (e, { markerId, lat, lng }) => {
        setHighlighted(markerId);
    }

    useEffect(() => {
        setCurrCoordinates(props.locations);
        redrawAndResize(myMap);
        // eslint-disable-next-line
    }, [props, myMap]);

    useEffect(() => {
        if (!mapConfigs) {
            searchService.getMapConfigs().then((configs) => {
                console.log('configs', configs);
                setMapConfigs(configs);
            });
        }
    }, [mapConfigs]);

    return (
        <main>
            {mapConfigs && <div className="map-container">
                <GoogleMap
                    apiKey={mapConfigs.apiKey}
                    defaultCenter={mapConfigs.mapCenter}
                    defaultZoom={mapConfigs.zoom}
                    options={mapOptions}
                    onGoogleApiLoaded={onGoogleApiLoaded}
                    yesIWantToUseGoogleMapApiInternals
                    onChange={onMapChange}
                >
                    {currCoordinates.map(({ latitude, longitude, name }, index) => (
                        <Marker
                            key={index}
                            lat={latitude}
                            lng={longitude}
                            markerId={name}
                            onClick={onMarkerClick}
                            className="marker"
                        />
                    ))}
                </GoogleMap>
                {highlighted && (
                    <div className="highlighted">
                        {highlighted}{' '}
                        <button type="button" onClick={() => setHighlighted(null)}>
                            X
                        </button>
                    </div>
                )}
            </div>}
        </main>
    )
};

Map2.propTypes = {};

Map2.defaultProps = {};

export default Map2;
