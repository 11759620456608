import React, {useEffect, useState} from 'react';
import {connect, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { logout } from '../../store/actions/AuthActions';
import { isAuthenticated } from '../../store/selectors/AuthSelectors';
import * as authService from "../../services/AuthService";

function LogoutPage(props){
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    useEffect(() => {
        const currentUser = authService.getCurrentUser();
        if (currentUser) {
            setIsLoggedIn(true);
        }
    }, []);
    const dispatch = useDispatch();

    function onLogout() {
       dispatch(logout(props.history));
    }

    return(
        <>
            { isLoggedIn &&
                <Link to={"#"} className="site-button-link white" onClick={onLogout}>
                    <i className="ti-import m-r5 rotate90"></i> Logout
                </Link>
            }

			{ !isLoggedIn &&
                <Link to={"#"} className="site-button-link white" onClick={onLogout}>
                    <i className="ti-import m-r5 rotate90"></i> Login
                </Link>
            }
        </>
    )
} 
const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};

export default withRouter(connect(mapStateToProps)(LogoutPage));