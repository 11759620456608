import axiosInstance from '../services/AxiosInstance';

class SearchService {

    search(query) {
        return axiosInstance.get('/search?query=' + query).then(results => {
            return results.data;
        });
    }

    getMapConfigs() {
        return axiosInstance.get('/map/configs/').then(results => {
            return results.data;
        });
    }

    extractBusiness() {
        return axiosInstance.get('/extract/business').then(results => {
            return results.data;
        });
    }

    extractCategory() {
        return axiosInstance.get('/extract/category').then(results => {
            return results.data;
        });
    }

    extractEvent() {
        return axiosInstance.get('/extract/event').then(results => {
            return results.data;
        });
    }
}

const searchService = new SearchService();
export default searchService;